export const convertToArray = (value: unknown) => {
  if (!value) {
    return []
  }

  return Array.isArray(value) ? value : [value]
}

export const hasDuplicates = (array: unknown[]) => {
  if (!Array.isArray(array) || !array) {
    return false
  }

  return new Set(array).size !== array.length
}

export const getUniqueListByKey = <T extends object>(
  array: T[],
  key: keyof T
) => {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}
