import { call, put, takeEvery, spawn } from 'redux-saga/effects'

import {
  fetchFeedbackRates,
  fetchFeedbackSummary,
  sendFeedback,
} from '@/store/feedback'
import { notificationsCreate } from '@/store/notifications'
import { NOTIFICATIONS, SENTRY } from '@/constants'
import { SagaError, errorHandler } from '@/utils/sagas'
import { OK } from '@/constants/utils'
import appsApi from '@/apis/apps'

export function* requestFeedbackRates() {
  try {
    const { status, json } = yield call(appsApi.getAppRates)

    if (status === OK) {
      yield put(fetchFeedbackRates.fulfilled({ items: json }))
    } else {
      throw new Error(`Cannot fetch applications rates: ${json}`)
    }
  } catch (error) {
    yield put(fetchFeedbackRates.rejected({ error: error?.message }))
  }
}

export function* requestFeedbackSummary({ payload }) {
  try {
    const { status, json } = yield call(appsApi.getFeedbackSummary, payload.id)

    if (status === OK) {
      yield put(fetchFeedbackSummary.fulfilled({ summary: json }))
    } else {
      throw new Error(`Cannot fetch applications feedback: ${json}`)
    }
  } catch (error) {
    yield put(fetchFeedbackSummary.rejected({ error: error?.message }))
  }
}

export function* requestSendApplicationRate({ payload: { id, ...body } }) {
  try {
    const { status, json } = yield call(appsApi.rateApp, id, {
      ...body,
    })

    if (status === OK) {
      yield put(sendFeedback.fulfilled({ summary: json }))

      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.SUCCESS,
          content: 'Thank you for rating this app.',
          autoHideDelayTime: NOTIFICATIONS.RATING_AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      throw new SagaError(
        `requestSendApplicationRate(): Cannot send rate - appId: ${id}`,
        json,
        status
      )
    }
  } catch (error) {
    yield put(sendFeedback.rejected({ error: error?.message }))
    yield spawn(errorHandler, error, SENTRY.TAGS.CART)

    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `Unable to rate this app, please try again or contact our support. [${
          error?.message || error
        }]`,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* watchFeedbackRequests() {
  yield takeEvery(fetchFeedbackRates.pending, requestFeedbackRates)
  yield takeEvery(fetchFeedbackSummary.pending, requestFeedbackSummary)
  yield takeEvery(sendFeedback.pending, requestSendApplicationRate)
}
