import {
  put,
  take,
  takeEvery,
  call,
  select,
  spawn,
  all,
  delay,
} from 'redux-saga/effects'

import { notificationsCreate } from '@/store/notifications'
import { userLogIn, userLogOut } from '@/store/user'
import {
  addProduct,
  addProductForNotLoggedIn,
  addMultipleProducts,
  addMultipleProductsForNotLoggedIn,
  removeProduct,
  updateProduct,
  load,
  create,
  addCoupon,
  cleanCart,
} from './'
import cartApi from '@/apis/cart'
import {
  getCart,
  canBuy,
  isAppInstalled,
  isPrivateApp,
  getAppPayment,
  checkIfIsService,
  getReadyToBuyApps,
  isActiveSubscription,
  getProductDesignation,
  getPublishedApp,
} from '@/store/selectors'
import { OK, NOT_FOUND, BAD_REQUEST } from '@/constants/utils'
import { NOTIFICATIONS, SENTRY, CART, AMPLITUDE_EVENTS } from '@/constants'
import { getErrorCode } from '@/utils/api'
import { SagaError, errorHandler } from '@/utils/sagas'
import { pushEvent } from '@/utils/analytics'
import { sendPostMessage, PM_EVENT, isInIframe } from '@/utils/browser'
import { getDesignationLabel } from '@/utils/products'
import { fetchSubscription } from '@/store/subscription'
import { logAmplitudeEvent } from '@/store/stats'

const checkCartLocked = (error) => {
  if (!error) {
    return false
  }

  const isBadRequest = error && error.status === BAD_REQUEST
  const errorCode = error && error.data && getErrorCode(error.data.errors).code

  return (
    isBadRequest && errorCode === CART.ERROR_CODE.PRODUCTS_CART_CHECKOUT_STARTED
  )
}

export function* cartLoad({ payload }) {
  try {
    const response = yield call(cartApi.get, payload.id)

    if (response?.status === OK) {
      yield put(load.fulfilled(response.json))

      if (isInIframe()) {
        sendPostMessage(PM_EVENT.MARKETPLACE_CART_UPDATE, {
          cart: response.json,
        })
      }
    } else {
      throw new SagaError(
        `cartLoad(): Cart couldn't be loaded [${payload?.id}]`,
        response?.json,
        response?.status
      )
    }
  } catch (error) {
    yield put(load.rejected({ error: error?.message }))

    let errorMessage = `Cart couldn't be loaded. Please, contact our support.`

    if (error?.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

/*
  Handle case when we're buying cart and not every item were bought.
  In this case we're creating new cart and add failed products to it.
*/
function* addProductsToNewCart(products, cartId) {
  try {
    const addItemsCalls = products.map((itemId) =>
      call(cartApi.addProduct, cartId, itemId)
    )
    const responses = yield all(addItemsCalls)
    const allResponsesStatus = responses.every(({ status }) => status === OK)

    if (!allResponsesStatus) {
      throw new Error("Couldn't add products to new cart")
    }
  } catch (error) {
    throw new Error(error?.message)
  }
}

export function* cartCreate({ payload }) {
  try {
    const recentResponse = yield call(cartApi.getRecent)

    // Recent cart not found, create a new one
    if (recentResponse?.status === NOT_FOUND) {
      const createResponse = yield call(cartApi.create)

      if (createResponse?.status === OK) {
        const cartId = createResponse.json.id
        const products = payload?.products

        if (products?.length) {
          yield call(addProductsToNewCart, products, cartId)
        }

        yield put(create.fulfilled())
        yield put(load.pending({ id: createResponse.json.id }))
      } else {
        throw new SagaError(
          "cartCreate(): Cart couldn't be created",
          createResponse?.json,
          createResponse?.status
        )
      }
    } else if (recentResponse?.status === OK) {
      yield put(load.fulfilled(recentResponse.json))

      if (isInIframe()) {
        sendPostMessage(PM_EVENT.MARKETPLACE_CART_UPDATE, {
          cart: recentResponse.json,
        })
      }
    } else {
      throw new SagaError(
        "cartCreate(): Cart couldn't be created",
        recentResponse?.json,
        recentResponse?.status
      )
    }
  } catch (error) {
    yield put(create.rejected({ error: error?.message }))
    const canUserBuy = yield select(canBuy)

    let errorMessage = `Cart couldn't be created. Please, contact our support.`

    if (error?.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else if (canUserBuy) {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* cartUpdateProduct({ payload }) {
  try {
    const cart = yield select(getCart)
    const { productId, planId } = payload

    const response = yield call(
      cartApi.updateProductPlan,
      cart.id,
      productId,
      planId
    )

    if (response?.status === OK) {
      yield put(load.pending({ id: cart.id }))
      yield put(updateProduct.fulfilled())
    } else {
      throw new SagaError(
        `requestUpdateProductPlan(): Cart plan couldn't be updated [${cart.id}]`,
        response?.json,
        response?.status
      )
    }
  } catch (error) {
    yield put(updateProduct.rejected({ error: error?.message }))
    const isCartLocked = checkCartLocked(error)

    let errorMessage = `Cart couldn't be updated. Please, contact our support.`
    let notificationVariant = isCartLocked
      ? NOTIFICATIONS.VARIANTS.WARNING
      : NOTIFICATIONS.VARIANTS.ERROR

    if (error?.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else if (isCartLocked) {
      const cartId = error && error.data && error.data.id
      errorMessage = CART.ERRORS.CART_LOCKED

      if (cartId) {
        yield put(load.pending({ id: cartId }))
      }
    } else {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: notificationVariant,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* cartRemoveProduct({ payload }) {
  try {
    const cart = yield select(getCart)
    const { productId } = payload

    const response = yield call(cartApi.removeProduct, cart.id, productId)

    if (response?.status === OK) {
      yield put(load.pending({ id: cart.id }))
      yield put(removeProduct.fulfilled())
    } else {
      throw new SagaError(
        `cartRemoveProduct(): Couldn't remove the product [${productId}] from the cart [${cart.id}]`,
        response?.json,
        response?.status
      )
    }
  } catch (error) {
    yield put(removeProduct.rejected({ error: error?.message }))
    const isCartLocked = checkCartLocked(error)

    let errorMessage = `The product couldn't be removed from the cart. Please, contact our support.`
    let notificationVariant = isCartLocked
      ? NOTIFICATIONS.VARIANTS.WARNING
      : NOTIFICATIONS.VARIANTS.ERROR

    if (error?.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else if (isCartLocked) {
      const cartId = error && error.data && error.data.id
      errorMessage = CART.ERRORS.CART_LOCKED

      if (cartId) {
        yield put(load.pending({ id: cartId }))
      }
    } else {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: notificationVariant,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* cartAddProduct({ payload }) {
  try {
    const { productId } = payload
    const isAppAlreadyInstalled = yield select(isAppInstalled, productId)

    if (isAppAlreadyInstalled) {
      throw new Error(NOTIFICATIONS.ERRORS.APP_ALREADY_INSTALLED)
    }

    const cart = yield select(getCart)
    const response = yield call(cartApi.addProduct, cart.id, productId)

    if (response?.status === OK) {
      yield put(load.pending({ id: cart.id }))
      yield take([load.fulfilled, load.rejected])
      yield put(
        addProduct.fulfilled({
          ...payload,
          productId,
        })
      )
    } else {
      throw new SagaError(
        `cartAddProduct(): Couldn't add the product [${productId}] to the cart [${cart.id}]`,
        response?.json,
        response?.status
      )
    }
  } catch (error) {
    yield put(addProduct.rejected({ error: error?.message }))
    const isCartLocked = checkCartLocked(error)

    let errorMessage = `The product couldn't be added to the cart. Please, contact our support.`
    let notificationVariant = isCartLocked
      ? NOTIFICATIONS.VARIANTS.WARNING
      : NOTIFICATIONS.VARIANTS.ERROR

    if (error?.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else if (error?.message === NOTIFICATIONS.ERRORS.APP_ALREADY_INSTALLED) {
      errorMessage = NOTIFICATIONS.ERRORS.APP_ALREADY_INSTALLED
    } else if (isCartLocked) {
      const cartId = error && error.data && error.data.id
      errorMessage = CART.ERRORS.CART_LOCKED

      if (cartId) {
        yield put(load.pending({ id: cartId }))
      }
    } else {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: notificationVariant,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* cartAddMultipleProducts({ payload }) {
  try {
    const { productIds } = payload
    const filteredProducts = yield select(getReadyToBuyApps, productIds)

    const cart = yield select(getCart)
    const response = yield call(cartApi.addProducts, cart.id, filteredProducts)

    if (response?.status === OK) {
      yield delay(500) // Minimize risk of race on backend side
      yield put(load.pending({ id: cart.id }))
      yield put(addMultipleProducts.fulfilled())
    } else {
      throw new SagaError(
        `cartAddMultipleProducts(): Couldn't add the products [${productIds.join(
          ','
        )}] to the cart [${cart.id}]`,
        response?.json,
        response?.status
      )
    }
  } catch (error) {
    yield put(addMultipleProducts.rejected({ error: error?.message }))
    const isCartLocked = checkCartLocked(error)

    let errorMessage = `Products couldn't be added to the cart. Please, try again later or contact our support.`
    let notificationVariant = isCartLocked
      ? NOTIFICATIONS.VARIANTS.WARNING
      : NOTIFICATIONS.VARIANTS.ERROR

    if (error && error.adblockDetected) {
      errorMessage = NOTIFICATIONS.ERRORS.ADBLOCK
    } else if (isCartLocked) {
      const cartId = error && error.data && error.data.id
      errorMessage = CART.ERRORS.CART_LOCKED

      if (cartId) {
        yield put(load.pending({ id: cartId }))
      }
    } else {
      yield spawn(errorHandler, error, SENTRY.TAGS.CART)
    }

    yield put(
      notificationsCreate({
        variant: notificationVariant,
        content: errorMessage,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* cartAddProductForNotLoggedIn({ payload }) {
  yield all([
    take(userLogIn.fulfilled),
    take(load.fulfilled),
    take(fetchSubscription.fulfilled),
  ])

  const { productId } = payload

  const productType = yield select(getProductDesignation, productId)
  const isProductSubscriptionActive = yield select(
    isActiveSubscription,
    productType
  )

  if (!isProductSubscriptionActive) {
    const productLabel = getDesignationLabel(productType)
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `Your ${productLabel} license is inactive. Go to ${productLabel} app and activate it.`,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    return
  }

  const isPrivate = yield select(isPrivateApp, productId)
  const isPaid = yield select(getAppPayment, productId)
  const isService = yield select(checkIfIsService, productId)

  if (isPrivate && isPaid) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `You cannot buy your own ${
          isService ? 'service' : 'app'
        }. If you want to
        install it, go to Developer Console.`,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    return
  }

  yield call(cartAddProduct, { payload })
}

export function* cartAddMultipleProductForNotLoggedIn({ payload }) {
  yield all([take(userLogIn.fulfilled), take(load.fulfilled)])

  yield call(cartAddMultipleProducts, { payload })
}

function* couponAdd({ payload }) {
  try {
    const { coupon } = payload
    const cart = yield select(getCart)
    const response = yield call(cartApi.addCoupon, cart.id, coupon)

    if (response && response.status === OK) {
      yield put(addCoupon.fulfilled())
      yield put(load.pending({ id: cart.id }))
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.SUCCESS,
          content: 'The coupon has been added successfully.',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      yield put(
        addCoupon.rejected({
          error: 'The coupon has failed due to the incorrect response status.',
        })
      )
    }
  } catch (error) {
    yield put(addCoupon.rejected({ error: error?.message }))
  }
}

function* sendProductAddedToCartEvents({
  payload: { productId: appId, cartId, sourceId },
}) {
  const app = yield select(getPublishedApp, appId)

  const eventPayload = {
    appId,
    appName: app?.name,
    ...(sourceId && { source_id: sourceId }),
    ...(cartId && { cartId }),
  }

  yield pushEvent({
    event: 'addToCartMarketplace',
    payload: eventPayload,
  })
  yield put(
    logAmplitudeEvent({
      name: AMPLITUDE_EVENTS.APP_ADDED_TO_CART,
      params: eventPayload,
    })
  )
}

function* cleanCartInfo() {
  yield put(cleanCart())
}

export function* watchCartRequests() {
  yield takeEvery(load.pending, cartLoad)
  yield takeEvery(create.pending, cartCreate)
  yield takeEvery(updateProduct.pending, cartUpdateProduct)
  yield takeEvery(removeProduct.pending, cartRemoveProduct)
  yield takeEvery(addProduct.pending, cartAddProduct)
  yield takeEvery(addProduct.fulfilled, sendProductAddedToCartEvents)
  yield takeEvery(
    addProductForNotLoggedIn.pending,
    cartAddProductForNotLoggedIn
  )
  yield takeEvery(addMultipleProducts.pending, cartAddMultipleProducts)
  yield takeEvery(
    addMultipleProductsForNotLoggedIn.pending,
    cartAddMultipleProductForNotLoggedIn
  )
  yield takeEvery(addCoupon.pending, couponAdd)
  yield takeEvery(userLogOut.fulfilled, cleanCartInfo)
}
