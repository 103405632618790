import * as FullStory from '@fullstory/browser'

import { userLogIn } from '@/store/user'
import { isProd } from '@/utils/environment'
import { decodeQueryString } from '@/utils/browser'
import { AUTH_TYPE } from '@/constants/utils'
import { store } from '@/store'
import AccountsSDKManager from './accountsSdk'

const ERROR = {
  TIMEOUT: 'Timeout',
}

class UserSession {
  constructor() {
    this._accountsSdk = new AccountsSDKManager()
  }

  _initTrackers = () => {
    if (isProd) {
      FullStory.init({
        orgId: process.env.GATSBY_FULLSTORY_ORG,
        recordOnlyThisIFrame: true,
      })
    }
  }

  _signInFail = (error = ERROR.TIMEOUT) => {
    store.dispatch(userLogIn.rejected({ error: error?.message || error }))
  }

  _signInSuccess = ({
    scope,
    access_token,
    account_id,
    organization_id,
    is_ghost_login = false,
  }) => {
    store.dispatch(
      userLogIn.pending({
        scope,
        token: access_token,
        organizationId: organization_id,
        accountId: account_id,
        isGhostLogin: is_ghost_login,
      })
    )

    this._initTrackers()
  }

  signIn = async (method = AUTH_TYPE.popup) => {
    try {
      const userData = await this._accountsSdk.authorize(method)
      this._signInSuccess(userData)
    } catch (error) {
      this._signInFail(error)
    }
  }

  signOut = () => {
    this._accountsSdk.clear()
  }

  init = () => {
    const { frenzied } = decodeQueryString(window.location.hash)
    const isGhostLogin = frenzied === '1'

    if (isGhostLogin) {
      // replacing # with & is a quick adaptation of helper
      // dedicated to deal with query params
      const userData = decodeQueryString(window.location.hash.replace('#', '&'))

      userSession._signInSuccess({
        ...userData,
        scope: decodeURIComponent(userData?.scope),
        is_ghost_login: isGhostLogin,
      })
    } else {
      this.signIn(AUTH_TYPE.iframe)
    }
  }
}

const userSession = new UserSession()
export default userSession
