import includes from 'lodash/includes'

import {
  DEFAULT_PROTOCOL_VERSION,
  EXCLUDE_COMPATIBILITY_STORAGE_KEY,
  FILTERS,
  PAYMENT,
} from '@/constants'
import {
  getUrlParams,
  filterOutParams,
  getSessionStorageValue,
  setSessionStorageItem,
} from '@/utils/browser'
import { convertToArray } from '@/utils/func'
import { isHelpDesk, isLiveChat, PRODUCT } from '@/utils/environment'
import { sortByPopularity, sortByOrderAndInstallations } from './sorts'
import { APP_WIDGET_PLACEMENT } from '@/constants/utils'
import { isLegacyApp } from './legacy'
import { isAppleBusinessChat, isBotEngine, isHubSpot } from './apps'

const isObjEmpty = (obj) => !obj

export const filterByQuery = (query) => (item) => {
  if (!query) return true

  const queries = query
    .split(' ')
    .map((query) => query.toLowerCase())
    .filter((query) => query)
  const name = item.name.toLowerCase()
  const shortDescription = item.shortDescription.toLowerCase()
  const category = item.category ? item.category.name.toLowerCase() : ''

  return queries.some(
    (query) =>
      name.indexOf(query) !== -1 ||
      shortDescription.indexOf(query) !== -1 ||
      category.indexOf(query) !== -1
  )
}

export const filterByTypes = (types) => (item) => {
  if (!types || types.length === 0) return true
  return types.some((type) => !isObjEmpty(item[type]))
}

export const filterOutByTypes = (types) => (item) => {
  if (!types || types.length === 0) return true
  return types.some((type) => isObjEmpty(item[type]))
}

export const filterInstalledOnly = (installedOnly, installedApps) => (item) => {
  if (!installedOnly) return true
  return includes(installedApps, item.id)
}

export const filterOutNotInstalledHiddenApps = (installedApps) => (item) => {
  return includes(installedApps, item.id) || !item.hidden
}

export const filterOutBeta = (protocolVersion) => (item) => {
  if (protocolVersion === '2' && item.customProps.usingBeta === 'true') {
    return false
  }
  return true
}

export const filterOutHiddenForBeta =
  (protocolVersion = DEFAULT_PROTOCOL_VERSION) =>
  (item) => {
    if (
      protocolVersion === DEFAULT_PROTOCOL_VERSION &&
      item.customProps &&
      item.customProps.hiddenForBeta === 'true'
    ) {
      return false
    }
    return true
  }

export const filterOutServices = (item) => {
  if (item.customProps && item.customProps.isService === 'true') {
    return false
  }
  return true
}

export const filterOutNotInstalledThemes = (installedApps) => (item) => {
  if (includes(installedApps, item.id) && item.theme) {
    return true
  } else if (item.theme) {
    return false
  }

  return true
}

export const filterAppsForLicense = (
  apps = [],
  installedApps = [],
  protocolVersion,
  sortBy = sortByPopularity
) => {
  let updatedApps = apps
    .filter(filterOutNotInstalledHiddenApps(installedApps))
    .filter(filterOutBeta(protocolVersion))
    .filter(filterOutHiddenForBeta(protocolVersion))
    .filter(filterOutServices)
    .filter(filterOutNotInstalledThemes(installedApps))

  if (sortBy) {
    updatedApps = updatedApps.sort(sortBy)
  }

  return updatedApps
}

export const filterByPaymentType = (app, paymentType, isPerAccount) => {
  const payment = app?.payment

  switch (paymentType) {
    case 'free':
      return !payment

    case 'once':
      return payment?.frequency === paymentType

    case 'monthly':
      return (
        payment &&
        payment?.frequency === paymentType &&
        payment?.perAccount === isPerAccount
      )

    default:
      return true
  }
}

export const filterOutInvalidDevelopers = ({ apps = [], services = [] }) =>
  apps?.length || services?.length

export const filterForDevsOtherProducts = (
  products = [],
  ownerId,
  productId
) => {
  if (ownerId === process.env.GATSBY_LC_OWNER_ID) {
    // if owner is LC return only paid products
    return products.filter(
      (product) => product.payment && product.id !== productId
    )
  }

  return products.filter((product) => product.id !== productId)
}

export const filterOutInstalledApps = (installedAppsIds) => (app) =>
  !installedAppsIds.includes(app.id)

const getCategoriesIds = (categories) => {
  const categoriesOptionsArray = Object.values(FILTERS.CATEGORY.OPTIONS)
  const categoriesOptions = categoriesOptionsArray.reduce((acc, option) => {
    acc[option.VALUE] = option.ID
    return acc
  }, {})

  return categories.map((value) => categoriesOptions[value]).filter(Boolean)
}

export const getDefaultCompatibilityFilterValue = () => {
  if (isLiveChat) {
    return PRODUCT.LiveChat
  }

  if (isHelpDesk) {
    return PRODUCT.HelpDesk
  }

  return PRODUCT.Text
}

export const checkIfCompatibilityExcluded = (filters = {}) => {
  const excludeCompatibilityValue = getSessionStorageValue(
    EXCLUDE_COMPATIBILITY_STORAGE_KEY
  )
  const excludeCompatibility =
    excludeCompatibilityValue !== null && excludeCompatibilityValue

  if (excludeCompatibility) {
    return filters
  }

  const compatibilityFilterValue = getDefaultCompatibilityFilterValue()

  if (compatibilityFilterValue === PRODUCT.Text) {
    return filters
  }

  return { ...filters, [FILTERS.COMPATIBILITY.ID]: compatibilityFilterValue }
}

export const handleCompatibilityExclusion = ({ compatibility }) => {
  const isCompatibilitySelected = !!(compatibility || compatibility?.length)

  setSessionStorageItem(
    EXCLUDE_COMPATIBILITY_STORAGE_KEY,
    !isCompatibilitySelected
  )
}

export const getDefaultCategoryFilter = (
  categoryId,
  filterOptions = FILTERS.CATEGORY.OPTIONS
) => {
  const categoriesFilters = Object.values(filterOptions)
  const category = categoriesFilters.find((filter) => filter.ID === categoryId)

  return category && category.VALUE
}

export const checkIfSupportedFilters = (filters, supportedFilters) => {
  const checkedFilters = {}

  Object.keys(filters).forEach((key) => {
    if (supportedFilters && supportedFilters.includes(key)) {
      checkedFilters[key] = filters[key]
    } else if (!supportedFilters) {
      checkedFilters[key] = filters[key]
    }
  })

  return checkedFilters
}

// FILTERS:
export const filterByCategories = (categories) => (app) => {
  if (
    !categories ||
    categories.includes('all') ||
    (Array.isArray(categories) && !categories.length)
  ) {
    return true
  }

  const appCategoryId = app.category && app.category.id
  const categoriesIds = getCategoriesIds(convertToArray(categories))

  return categoriesIds.some((categoryId) => appCategoryId === categoryId)
}

export const filterByCountries = (countries) => (product) => {
  if (
    !countries ||
    countries.includes('all') ||
    (Array.isArray(countries) && !countries.length)
  ) {
    return true
  }

  const productCountryId = product?.country
  const countriesIds = convertToArray(countries)

  return countriesIds.some((countryId) => productCountryId === countryId)
}

export const filterByPaymentTypes = (paymentType) => (app) => {
  const pickedValues = convertToArray(paymentType)

  if (!paymentType || !pickedValues.length) {
    return true
  }

  const isFree = pickedValues.includes(FILTERS.PAYMENT.OPTIONS.FREE.VALUE)
  const isOneTimePayment = pickedValues.includes(
    FILTERS.PAYMENT.OPTIONS.ONE_TIME_PAYMENT.VALUE
  )
  const isRecurrentPerLicense = pickedValues.includes(
    FILTERS.PAYMENT.OPTIONS.RECURRENT_PER_LICENSE.VALUE
  )
  const isRecurrentPerAccount = pickedValues.includes(
    FILTERS.PAYMENT.OPTIONS.RECURRENT_PER_AGENT.VALUE
  )

  const isFreeCondition = isFree && !app.payment

  const isOneTimePaymentCondition =
    isOneTimePayment &&
    app.payment &&
    app.payment.frequency === PAYMENT.DIRECT_CHARGE

  const isRecurrentPerLicenseCondition =
    isRecurrentPerLicense &&
    app.payment &&
    app.payment.frequency === PAYMENT.RECURRENT_CHARGE &&
    app.payment.perAccount === false

  const isRecurrentPerAccountCondition =
    isRecurrentPerAccount &&
    app.payment &&
    app.payment.frequency === PAYMENT.RECURRENT_CHARGE &&
    app.payment.perAccount === true

  return (
    isFreeCondition ||
    isOneTimePaymentCondition ||
    isRecurrentPerLicenseCondition ||
    isRecurrentPerAccountCondition ||
    false
  )
}

export const filterByProductDesignation =
  (products) =>
  ({ product }) => {
    const pickedValues = convertToArray(products)
    let productDesignation = product || PRODUCT.LiveChat

    if (!pickedValues?.length) {
      return true
    }

    return pickedValues.includes(productDesignation)
  }

export const filterByPlacement = (placement) => (product) => {
  const pickedValues = convertToArray(placement)

  if (!placement || !pickedValues.length) {
    return true
  }

  const isSettingsPlacement = pickedValues.includes(
    FILTERS.WIDGET_PLACEMENT.OPTIONS.SETTINGS.VALUE
  )
  const isPluginPlacement = pickedValues.includes(
    FILTERS.WIDGET_PLACEMENT.OPTIONS.PLUGIN.VALUE
  )
  const isFullscreenPlacement = pickedValues.includes(
    FILTERS.WIDGET_PLACEMENT.OPTIONS.FULL_SCREEN.VALUE
  )
  const isMessageBoxPlacement = pickedValues.includes(
    FILTERS.WIDGET_PLACEMENT.OPTIONS.MESSAGE_BOX.VALUE
  )
  const isTargetedMessagePlacement = pickedValues.includes(
    FILTERS.WIDGET_PLACEMENT.OPTIONS.TARGETED_MESSAGE.VALUE
  )

  const productWidgets = product?.widgets || []

  const hasWidgetSettingsPlacement =
    isSettingsPlacement &&
    productWidgets?.some(
      (widget) => widget.placement === APP_WIDGET_PLACEMENT.SETTINGS
    )

  const hasWidgetPluginPlacement =
    isPluginPlacement &&
    productWidgets?.some(
      (widget) => widget.placement === APP_WIDGET_PLACEMENT.PLUGIN
    )

  const hasWidgetFullscreenPlacement =
    isFullscreenPlacement &&
    productWidgets?.some(
      (widget) => widget.placement === APP_WIDGET_PLACEMENT.FULL_SCREEN
    )

  const hasWidgetMessageBoxPlacement =
    isMessageBoxPlacement &&
    productWidgets?.some(
      (widget) => widget.placement === APP_WIDGET_PLACEMENT.MESSAGE_BOX
    )

  const hasTargetedMessagePlacement =
    isTargetedMessagePlacement &&
    productWidgets?.some(
      (widget) => widget.placement === APP_WIDGET_PLACEMENT.TARGETED_MESSAGE
    )

  return (
    hasWidgetSettingsPlacement ||
    hasWidgetPluginPlacement ||
    hasWidgetFullscreenPlacement ||
    hasWidgetMessageBoxPlacement ||
    hasTargetedMessagePlacement ||
    false
  )
}

export const isTutorialApp = (app) => {
  const { tutorialUrl, id, slug, theme, widgets, payment } = app

  const hasTutorial = !!tutorialUrl
  const isLegacyApplication = isLegacyApp(id)
  const hasTheme = !!theme
  const hasWidgets = !!widgets && widgets.length > 0
  const isChatBot = isBotEngine(id)

  return (
    (hasTutorial && isAppleBusinessChat(slug)) ||
    (hasTutorial &&
      !isLegacyApplication &&
      !hasTheme &&
      !hasWidgets &&
      !isHubSpot(id) &&
      !isChatBot &&
      !payment)
  )
}

// CUSTOM:
export const displayCustomPaymentValue = (values) => {
  const checkedValues = convertToArray(values)
  const paymentFilterOptions = Object.values(FILTERS.PAYMENT.OPTIONS)
  const customLabels = []

  checkedValues.forEach((value) => {
    const filterOption = paymentFilterOptions.find(
      (option) => option.VALUE === value
    )

    if (filterOption) {
      customLabels.push(filterOption.SHORT_LABEL)
    }
  })

  switch (checkedValues.length) {
    case paymentFilterOptions.length:
      return 'free and paid'

    case 1:
      return customLabels[0]

    case 2:
      return customLabels.join(' and ')

    default:
      return customLabels.join(', ')
  }
}

export const groupByCategories = (apps, protocolVersion, limit = 2) => {
  const categoriesApps = {}
  const filteredApps = {}
  const appsArray = Object.keys(apps).map((id) => apps[id])
  const licenseApps = filterAppsForLicense(appsArray, [], protocolVersion)

  licenseApps.forEach((app) => {
    if (app && app.category) {
      const { id: categoryId } = app.category

      if (!categoriesApps[categoryId]) {
        categoriesApps[categoryId] = []
      }

      categoriesApps[categoryId].push(app)
    }
  })

  Object.keys(categoriesApps).forEach((categoryId) => {
    const categoryApps = categoriesApps[categoryId]
      .sort(sortByOrderAndInstallations)
      .slice(0, limit)

    filteredApps[categoryId] = categoryApps
  })

  return filteredApps
}

export const filterByAllServices = (services) => (service) => {
  if (
    !services ||
    services.includes('base') ||
    (Array.isArray(services) && !services.length)
  ) {
    return true
  }

  const serviceCategory = service?.category?.id
  return services.includes(serviceCategory)
}

export const filterByLanguages = (languages) => (expert) => {
  if (!languages || (Array.isArray(languages) && !languages.length)) {
    return true
  }

  const expertLanguages = convertToArray(expert && expert.languages)
  const pickedLanguages = convertToArray(languages)

  return expertLanguages.some((expertLanguage) =>
    pickedLanguages.some((pickedLanguage) => expertLanguage === pickedLanguage)
  )
}

export const filterByIndustries = (industries, options) => (expert) => {
  if (!industries || (Array.isArray(industries) && !industries.length)) {
    return true
  }

  const expertIndustries = convertToArray(expert && expert.industries)
  const pickedIndustries = convertToArray(industries).map((industry) =>
    options.find(({ VALUE }) => VALUE === industry)
  )

  return expertIndustries.some((expertLanguage) =>
    pickedIndustries.some(({ LABEL }) => expertLanguage === LABEL)
  )
}

export const filterByPartnerServices = (services) => (expert) => {
  if (!services || (Array.isArray(services) && !services.length)) {
    return true
  }

  const expertServices = convertToArray(expert && expert.types)
  const pickedServices = convertToArray(services)

  return expertServices.some((expertService) =>
    pickedServices.some((pickedService) => expertService === pickedService)
  )
}

export const getInitialFilters = (defaultFilters = {}, supportedFilters) => {
  const { sortBy, ...filtersParams } = getUrlParams()

  const filters = { ...defaultFilters, ...filtersParams }

  return filterOutParams(filters, supportedFilters)
}

export const callFilters = (functions, initialItems) => {
  if (!functions || !functions.length) {
    return initialItems
  }

  return functions.reduce((items, func) => items.filter(func), initialItems)
}

export const getFilteringMethods = (filters, filtersOptions) => {
  const filteringMethods = []

  if (filters[FILTERS.COMPATIBILITY.ID]) {
    filteringMethods.push(
      filterByProductDesignation(filters[FILTERS.COMPATIBILITY.ID])
    )
  }

  if (filters[FILTERS.PAYMENT.ID]) {
    filteringMethods.push(filterByPaymentTypes(filters[FILTERS.PAYMENT.ID]))
  }

  if (filters[FILTERS.WIDGET_PLACEMENT.ID]) {
    filteringMethods.push(
      filterByPlacement(filters[FILTERS.WIDGET_PLACEMENT.ID])
    )
  }

  if (filters[FILTERS.CATEGORY.ID] && filters[FILTERS.CATEGORY.ID].length > 0) {
    filteringMethods.push(filterByCategories(filters[FILTERS.CATEGORY.ID]))
  }

  if (filters[FILTERS.SERVICE.ID] && filters[FILTERS.SERVICE.ID].length > 0) {
    filteringMethods.push(filterByPartnerServices(filters[FILTERS.SERVICE.ID]))
  }

  if (filters[FILTERS.SERVICES.ID] && filters[FILTERS.SERVICES.ID].length > 0) {
    filteringMethods.push(filterByAllServices(filters[FILTERS.SERVICES.ID]))
  }

  if (filters[FILTERS.LANGUAGE.ID] && filters[FILTERS.LANGUAGE.ID].length > 0) {
    filteringMethods.push(filterByLanguages(filters[FILTERS.LANGUAGE.ID]))
  }

  if (filters[FILTERS.INDUSTRY.ID] && filters[FILTERS.INDUSTRY.ID].length > 0) {
    filteringMethods.push(
      filterByIndustries(
        filters[FILTERS.INDUSTRY.ID],
        filtersOptions[FILTERS.INDUSTRY.ID]
      )
    )
  }

  if (filters[FILTERS.COUNTRY.ID] && filters[FILTERS.COUNTRY.ID].length > 0) {
    filteringMethods.push(filterByCountries(filters[FILTERS.COUNTRY.ID]))
  }

  return filteringMethods
}
