// @ts-check
// @ts-ignore
import storeMetrics from '@livechat/store-metrics'
import 'react-app-polyfill/ie11'
import 'js-polyfills/url'

import { wrapWithProviders } from './src/providers'
import { initSentry } from './src/services/sentry'
import { initRoutine } from '@/utils/initRoutine'
import { activeEnv, isAgentApp, isProd, isTestEnv } from '@/utils/environment'
import { normalizeRouteUpdatePath, pushEvent } from '@/utils/analytics'
import {
  sendPostMessage,
  PM_EVENT,
  scrollTo,
  initializeAdblockDetector,
} from '@/utils/browser'

if (!isTestEnv) {
  initRoutine()
}

if (isProd) {
  initSentry(activeEnv)
}

/**
 * @type {import ('gatsby').GatsbyBrowser['onRouteUpdate']}
 */
export const onRouteUpdate = ({ location: { pathname, hash, search } }) => {
  if (hash) {
    window.setTimeout(() => scrollTo(hash))
  }

  let path = `/marketplace${pathname}${search}`

  // We cannot move this action into website's gatsby-browser.js, because it would create a separate onRouteUpdate handler instead of overriding this one
  if (isAgentApp) {
    path = normalizeRouteUpdatePath(pathname, search)
  }

  sendPostMessage(PM_EVENT.ROUTE_CHANGE, {
    pathname: path,
  })

  pushEvent({ event: 'virtualPageView', payload: {} })
}

export const onInitialClientRender = () => {
  storeMetrics()

  initializeAdblockDetector()

  // dirty fix for missing popstate listener
  // https://github.com/gatsbyjs/gatsby/issues/7261
  // @ts-ignore
  const GATSBY_NAVIGATE = window.___navigate || {}

  window.addEventListener('popstate', () => {
    if (!window.location.hash) {
      const url = window.location.pathname + window.location.search
      return GATSBY_NAVIGATE(url, { replace: true })
    }
  })
}

export const wrapRootElement = wrapWithProviders
