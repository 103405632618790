import { CardButtonOptions } from '@livechat/design-system-react-components/dist/components/Card/Card'

import { APP_WIDGET_PLACEMENT } from '@/constants/utils'
import { WIDGET_CARD_LINK } from '@/constants/products'
import {
  type FeedbackResponse,
  type IApplication,
} from '@/apis/apps/interfaces'

import { type GetWidgetCardButtonParams } from './interfaces'

import { navigateToIFrame } from '../navigate'
import { getProductApplicationsPath } from './urls'
import { getDesignationLabel } from './designation'
import { formatPrice } from './payments'

export const isHubSpot = (id: string) => id === 'hubspot'
export const isAppleBusinessChat = (slug: string) =>
  slug === 'apple-business-chat'
export const isBotEngine = (id: string) =>
  id === process.env.GATSBY_BOTENGINE_APP_ID

export const getWidgetCardButton = ({
  isPrivateApplication,
  isInReview,
  appId,
  slug,
  designation,
  widgetId,
  placement,
}: GetWidgetCardButtonParams): CardButtonOptions | Record<never, never> => {
  let buttonOptions: CardButtonOptions

  switch (placement) {
    case APP_WIDGET_PLACEMENT.FULL_SCREEN:
      buttonOptions = {
        children: 'Open app',
        onClick: (e) =>
          navigateToIFrame(
            e,
            `/app/${isPrivateApplication ? widgetId : slug}`,
            designation
          ),
      }
      break

    case APP_WIDGET_PLACEMENT.SETTINGS:
      buttonOptions = {
        children: 'Open settings',
        onClick: (e) =>
          navigateToIFrame(
            e,
            `${getProductApplicationsPath(designation)}/${
              isInReview ? appId : slug
            }`,
            designation
          ),
      }
      break

    case APP_WIDGET_PLACEMENT.PLUGIN:
    case APP_WIDGET_PLACEMENT.MESSAGE_BOX:
      buttonOptions = {
        children: 'Go to Chats',
        onClick: (e) =>
          navigateToIFrame(e, WIDGET_CARD_LINK.CHATS, designation),
      }
      break

    case 'reports':
      buttonOptions = {
        children: 'Go to Reports',
        onClick: (e) =>
          navigateToIFrame(e, WIDGET_CARD_LINK.REPORTS, designation),
      }
      break

    default:
      return {}
  }

  return { ...buttonOptions, kind: 'secondary' }
}

export const generateJsonLinkingDataAppSchema = (
  product: IApplication,
  feedback: FeedbackResponse
): string => {
  const {
    name,
    product: designation,
    screenshots,
    shortDescription,
    payment,
  } = product
  const { rate, votes, reviews } = feedback
  const designationLabel = getDesignationLabel(designation)
  const hasRating = rate > 0 && votes > 0
  const hasReviews = reviews.length > 0

  const jsonLdSchema = {
    '@context': 'https://schema.org/',
    '@type': 'SoftwareApplication',
    name: name,
    operatingSystem: designationLabel,
    image: screenshots?.length ? screenshots[0].large : '',
    description: shortDescription,
    applicationCategory: 'BusinessApplication',
    applicationSuite: designationLabel,
    ...(hasRating && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: rate,
        ratingCount: votes,
        ...(hasReviews && { reviewCount: reviews.length }),
        bestRating: 5,
        worstRating: 1,
      },
    }),
    offers: {
      '@type': 'Offer',
      price: payment?.price ? formatPrice(payment.price) : 0,
      priceCurrency: 'USD',
    },
  }

  return JSON.stringify(jsonLdSchema)
}
