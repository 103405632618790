/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import startsWith from 'lodash/startsWith'
import { navigate } from 'gatsby'

import { fetchInstalledApps } from '@/store/installedApps'
import { saveCart } from '@/store/cart'
import { JSONParse } from '@/utils/func'
import { URLS } from '@/constants'
import { store } from '@/store'
import { isInNestedIframe } from './iframe'

// POST MESSAGE EVENTS SUPPORTED IN THE MARKETPLACE AND AGENT APP
export const PM_EVENT = {
  BOTENGINE_INSTALLED: 'botengine_installed',
  BOTENGINE_REMOVED: 'botengine_removed',
  LOADED: 'loaded',
  NAVIGATE: 'navigate',
  PRELOAD: 'preload',
  ROUTE_CHANGE: 'route_change',
  APP_INSTALLED: 'app_installed',
  APP_REMOVED: 'app_removed',
  APP_EVENT: 'app_event',
  APP_PURCHASED: 'app_purchased',
  CART_PURCHASED: 'cart_purchased',
  CART_UPDATED: 'cart_updated',
  MARKETPLACE_CART_UPDATE: 'marketplace_cart_update',
  REFRESH_INSTALLED_APPS: 'refresh_installed_apps',
}

const normalizeAAPath = (pathname) => {
  pathname = pathname.replace('/apps/marketplace', '')

  // /apps route is the default route for AA
  if (!pathname || pathname === '/') {
    pathname = URLS.APPS.LINK
  }

  return pathname
}

export const initPostMessageListeners = () => {
  const receiveMessage = (event) => {
    if (
      startsWith(event.origin, process.env.GATSBY_IFRAME_URL) ||
      startsWith(event.origin, 'https://localhost') ||
      startsWith(event.origin, 'http://localhost') ||
      startsWith(event.origin, 'https://my.') ||
      startsWith(event.origin, 'http://my.')
    ) {
      handleAgentAppEvents(event)
    }
  }

  window.addEventListener('message', receiveMessage, false)
}

const handleAgentAppEvents = (event) => {
  const data = JSONParse(event.data)

  switch (data?.event_name) {
    case PM_EVENT.NAVIGATE: {
      const pathname = normalizeAAPath(data.event_data.pathname)
      navigate(pathname)
      break
    }

    case PM_EVENT.PRELOAD: {
      const pathname = normalizeAAPath(data.event_data.pathname)
      window?.___loader.loadPage(pathname)
      break
    }

    case PM_EVENT.REFRESH_INSTALLED_APPS: {
      store?.dispatch(fetchInstalledApps.pending())
      break
    }

    case PM_EVENT.CART_UPDATED: {
      const cart = data.event_data.cart
      store?.dispatch(saveCart(cart))
      break
    }

    default:
      return false
  }
}

export const sendPostMessage = (eventName, eventData) => {
  switch (eventName) {
    case PM_EVENT.LOADED:
    case PM_EVENT.ROUTE_CHANGE:
    case PM_EVENT.APP_INSTALLED:
    case PM_EVENT.APP_REMOVED:
    case PM_EVENT.APP_EVENT:
    case PM_EVENT.APP_PURCHASED:
    case PM_EVENT.CART_PURCHASED:
    case PM_EVENT.NAVIGATE:
    case PM_EVENT.MARKETPLACE_CART_UPDATE:
      const message = JSON.stringify({
        event_name: eventName,
        event_data: eventData || null,
      })

      if (isInNestedIframe()) {
        return top.postMessage(message, '*')
      }
      return parent.postMessage(message, '*')

    default:
      return false
  }
}
