import { addDays, format, getDaysInMonth, getDaysInYear } from 'date-fns'

import type {
  IPaymentCartItem,
  IPaymentCharge,
  IPaymentItem,
} from '@/store/payment/interfaces'
import type {
  ICardDetails,
  ISubscriptionProduct,
} from '@/store/subscription/interfaces'
import type { IApplication } from '@/apis/apps/interfaces'

import {
  formatPrice,
  getDesignationLabel,
  getProductTypeCartItemLists,
} from '@/utils/products'
import { CART, CART_ITEM, DATE_FORMAT, PAYMENT } from '@/constants'

export const getDirectPurchaseItemPricingInfo = (
  payment: NonNullable<IPaymentItem['payment'] | IApplication['payment']>,
  agentsCount: number,
  customProps: IPaymentItem['customProps'],
  months: number
) => {
  const { price, frequency: paymentFrequency } = payment
  const frequency =
    customProps?.paymentFrequency === PAYMENT.FREQUENCY.HOURLY
      ? PAYMENT.FREQUENCY.HOURLY
      : paymentFrequency

  const multiplier = payment.perAccount ? agentsCount : 1
  const multipliedPrice = multiplier * price
  const totalPrice =
    frequency === PAYMENT.FREQUENCY.MONTHLY
      ? multipliedPrice * months
      : multipliedPrice

  return { totalPrice, frequency }
}

export const calculateTaxedPrice = (
  paymentPrice: number,
  charge: IPaymentCharge | null
) => {
  const isTaxedPayment = !!charge?.taxes?.tax_rate
  if (!isTaxedPayment) {
    return paymentPrice
  }

  const taxRate = 1 + Number(charge?.taxes?.tax_rate || 0)

  return paymentPrice * taxRate
}

export const getDirectPurchaseItemPriceSubtitle = (
  price: number,
  isPerAccount: boolean,
  isHourlyFrequency: boolean,
  charge: IPaymentCharge | null
) => {
  const months = charge?.months || 1
  const totalPrice = price * months
  const formattedPrice = formatPrice(totalPrice, true)

  if (isHourlyFrequency) {
    return 'per hour'
  }

  if (isPerAccount) {
    return formattedPrice
  }

  return 'per organization'
}

export const getCartItemNameSubtitle = (
  item: IPaymentCartItem,
  installed = false
) => {
  const { note, trialDays, productType } = item
  const billingCycle = item?.plan?.billingCycle
  let subtitle = ''

  if (note) {
    if (note.startsWith(CART_ITEM.NOTE.BILLING_ERROR.BASE)) {
      subtitle = 'Payment failed'
    } else if (note === CART_ITEM.NOTE.DPS_ERROR.PRODUCT_INACTIVE) {
      const designationLabel = getDesignationLabel(productType)
      subtitle = `Was not installed, please activate ${designationLabel} first`
    } else {
      subtitle = 'Was not installed, please contact support'
    }
  } else if (installed) {
    const today = new Date()

    if (trialDays === 0) {
      if (billingCycle === CART.BILLING_CYCLE.PAY_ONCE) {
        subtitle = 'One time payment'
      } else if (billingCycle === CART.BILLING_CYCLE.PAY_MONTHLY) {
        const renewalDate = format(
          addDays(today, getDaysInMonth(today)),
          DATE_FORMAT.SHORT_DATE
        )

        subtitle = `Renews on ${renewalDate}`
      } else if (billingCycle === CART.BILLING_CYCLE.PAY_YEARLY) {
        const renewalDate = format(
          addDays(today, getDaysInYear(today)),
          DATE_FORMAT.SHORT_DATE
        )

        subtitle = `Renews on ${renewalDate}`
      }
    } else if (
      [CART.BILLING_CYCLE.PAY_MONTHLY, CART.BILLING_CYCLE.PAY_YEARLY].includes(
        billingCycle
      )
    ) {
      const endTrialDate = format(
        addDays(today, item.trialDays),
        DATE_FORMAT.SHORT_DATE
      )

      subtitle = `Free trial ends ${endTrialDate}`
    }
  } else if (trialDays === 0) {
    if (billingCycle === CART.BILLING_CYCLE.PAY_ONCE) {
      subtitle = 'One time payment'
    } else if (billingCycle === CART.BILLING_CYCLE.PAY_MONTHLY) {
      subtitle = 'Monthly payment'
    } else if (billingCycle === CART.BILLING_CYCLE.PAY_YEARLY) {
      subtitle = 'Yearly payment'
    }
  } else if (
    [CART.BILLING_CYCLE.PAY_MONTHLY, CART.BILLING_CYCLE.PAY_YEARLY].includes(
      billingCycle
    )
  ) {
    subtitle = `${item.trialDays}-day free trial`
  }

  return subtitle
}

export const getCartItemPricingInfo = (plan: IPaymentCartItem['plan']) => {
  const { price, billingCycle } = plan

  let billingType = ''
  if (billingCycle === CART.BILLING_CYCLE.PAY_MONTHLY) {
    billingType = '/mo'
  } else if (billingCycle === CART.BILLING_CYCLE.PAY_YEARLY) {
    billingType = '/yr'
  }

  return { totalPrice: formatPrice(price.total, true), billingType }
}

export const getCartItemBillingDate = (
  days: number,
  plan: IPaymentCartItem['plan']
) => {
  let billingDate
  let billingCycle = ''

  if (plan?.billingCycle) {
    billingCycle = plan.billingCycle
  }

  if (
    !days ||
    days === 0 ||
    ![CART.BILLING_CYCLE.PAY_MONTHLY, CART.BILLING_CYCLE.PAY_YEARLY].includes(
      billingCycle
    )
  ) {
    billingDate = 'billed now'
  } else {
    const date = format(addDays(new Date(), days), DATE_FORMAT.SHORT_DATE)
    billingDate = `billing starts ${date}`
  }

  return billingDate
}

export const getCartItemLists = (
  purchasedItems: IPaymentCartItem[],
  failedPurchaseItems: IPaymentCartItem[]
) => {
  const purchasedProductItemLists = getProductTypeCartItemLists(purchasedItems)
  const failedPurchaseItemList = [
    { cartItems: failedPurchaseItems, productType: '' },
  ]
  return [purchasedProductItemLists, failedPurchaseItemList]
}

export const isEveryCreditCardEqual = (
  creditCardA: ICardDetails | null,
  creditCardB: ICardDetails | null
) => {
  if (!creditCardA || !creditCardB) {
    return false
  }

  const {
    cardType: typeA,
    firstSix: firstSixA,
    lastFour: lastFourA,
  } = creditCardA || {}
  const {
    cardType: typeB,
    firstSix: firstSixB,
    lastFour: lastFourB,
  } = creditCardB || {}

  return typeA === typeB && firstSixA === firstSixB && lastFourA === lastFourB
}

export const isSameCard = (cartItemsSubscriptions: ISubscriptionProduct[]) =>
  cartItemsSubscriptions?.every((subscription) => {
    if (!subscription?.hasCard) {
      return false
    }

    return isEveryCreditCardEqual(
      subscription.cardDetails,
      cartItemsSubscriptions[0].cardDetails
    )
  })
