import { type ICart } from '@/store/cart/interfaces'

export const serializeCart = (cart: ICart) => {
  return {
    isFetching: false,
    isInitialized: true,
    acceptedUntil: cart.acceptedUntil,
    agents: cart.agents,
    closedAt: cart.closedAt,
    createdAt: cart.createdAt,
    checkedOutUntil: cart.checkedOutUntil,
    id: cart.id,
    license: cart.license,
    name: cart.name,
    products: cart.products || [],
    purchasedAt: cart.purchasedAt,
    seats: cart.seats,
    summary: cart.summary || {},
  }
}
