import { navigate } from 'gatsby'

import {
  sendPostMessage,
  PM_EVENT,
  isInIframe,
  canUseWindow,
} from '@/utils/browser'
import { URLS } from '@/constants'
import { getProductIFrameUrl } from './products'
import { activeProduct, isAgentApp } from './environment'

export const navigateToIFrame = (
  e,
  path,
  product = activeProduct,
  target = '_blank'
) => {
  if (e) {
    e.preventDefault()
  }
  const isActiveProduct = product === activeProduct
  const prefix = getProductIFrameUrl(product)

  if (isInIframe() && isActiveProduct) {
    navigateWithPostMessage(path)
  } else {
    navigateWithWindowOpen(prefix, path, target)
  }
}

export const navigateWithWindowOpen = (prefix, path, target = '_blank') => {
  if (canUseWindow) {
    window.open(prefix + path, target, 'noopener noreferrer')
  }
}

const navigateWithPostMessage = (pathname) => {
  if (isAgentApp && pathname === '/') {
    pathname = URLS.APPS.LINK
  }

  sendPostMessage(PM_EVENT.NAVIGATE, { pathname })
}

export const customNavigate = (to, opts) =>
  navigate(to === '/' && isAgentApp ? URLS.APPS.LINK : to, opts)
