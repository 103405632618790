import { navigate } from 'gatsby'
import {
  call,
  put,
  takeEvery,
  take,
  select,
  spawn,
  all,
  delay,
} from 'redux-saga/effects'

import {
  getPublishedApp,
  isAppPrivate,
  isAppInReview,
  checkCustomPermissions,
  canInstallProduct,
  isAvailableInCurrentPlan,
  isAppInstalled,
  getProductDesignation,
  getTrustedProductUrl,
  getToken,
  isTeamPlanApp,
  checkIfIsService,
  getUtmParameters,
} from '@/store/selectors'
import {
  fetchInstalledApps,
  installApp,
  uninstallApp,
  installTrustedApp,
  installAppNotLoggedIn,
  installTrustedAppNotLoggedIn,
} from '@/store/installedApps'
import { requestRecurrentChargeCancel } from '@/store/recurrentCharges/sagas'
import { notificationsCreate } from '@/store/notifications'
import { userLogIn, userLogOut } from '@/store/user'
import { logAmplitudeEvent } from '@/store/stats'
import appsApi from '@/apis/apps'
import { deleteConsent } from '@/apis/accounts/clients'
import { createApiRequest } from '@/utils/api'
import { pushEvent, getMarketplaceSourceUrl } from '@/utils/analytics'
import { sendPostMessage, PM_EVENT } from '@/utils/browser'
import { errorHandler } from '@/utils/sagas'
import { GET, OK, POST, DELETE } from '@/constants/utils'
import { PRODUCT, isAgentApp } from '@/utils'
import {
  AMPLITUDE_EVENTS,
  EVENT_SOURCE_IDS,
  NOTIFICATIONS,
  PAYMENT,
  SENTRY,
  SUPPORTED_UTM_PARAMETERS,
} from '@/constants'

function* requestInstalledAppList() {
  try {
    const { status, json } = yield call(createApiRequest, {
      method: GET,
      path: '/applications/installed',
    })

    const apps = json || []

    if (status === OK) {
      yield put(
        fetchInstalledApps.fulfilled({
          items: apps.map((item) => {
            return item.id
          }),
        })
      )
    } else {
      throw new Error("Installed apps list couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchInstalledApps.rejected({ error: error?.message }))
  }
}

export function* requestInstallApp({
  payload: { id, redirect = true, notification = true, accountStatus = '' },
}) {
  try {
    const isAppAlreadyInstalled = yield select(isAppInstalled, id)

    if (isAppAlreadyInstalled) {
      throw new Error(NOTIFICATIONS.ERRORS.APP_ALREADY_INSTALLED)
    }

    const app = yield select(getPublishedApp, id)

    const isService = yield select(checkIfIsService, id)
    const isPrivate = yield select(isAppPrivate, id)
    const isInReview = yield select(isAppInReview, id)
    const slug = app.slug

    const { status, error } = yield call(appsApi.installApp, id)

    if (status === OK) {
      yield put(
        installApp.fulfilled({
          id,
          receivedAt: Date.now(),
          sourceId: EVENT_SOURCE_IDS.INSTALL_BUTTON,
          ...(accountStatus && { accountStatus }),
        })
      )

      if (!isPrivate && !isService && redirect && !isInReview) {
        navigate(`/apps/${slug}?action=settings`)
      }

      if (notification) {
        yield put(
          notificationsCreate({
            variant: NOTIFICATIONS.VARIANTS.SUCCESS,
            content: isService
              ? 'Service activated successfully!'
              : 'Application installed successfully!',
            autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
          })
        )
      }
    } else {
      throw new Error("App couldn't be installed: " + error)
    }
  } catch (error) {
    yield put(installApp.rejected({ error: error?.message }))

    if (notification) {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.ERROR,
          content: error?.message,
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    }
  }
}

export function* requestInstallTrustedApp({
  payload: { id, redirect = true, notification = true },
}) {
  try {
    const isAppAlreadyInstalled = yield select(isAppInstalled, id)

    if (isAppAlreadyInstalled) {
      throw new Error(NOTIFICATIONS.ERRORS.APP_ALREADY_INSTALLED)
    }

    const trustedUrl = yield select(getTrustedProductUrl, id)
    const token = yield select(getToken)
    const isKb = [
      process.env.GATSBY_KNOWLEDGEBASE_APP_ID,
      process.env.GATSBY_KNOWLEDGEBASE_HD_APP_ID,
    ].includes(id)

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Bearer ${token}`)

    const options = {
      method: POST,
      headers,
    }

    if (isKb) {
      const utmParams = yield select(getUtmParameters)
      const utmSource = utmParams?.[SUPPORTED_UTM_PARAMETERS.UTM_SOURCE]
      const isDirectSource =
        utmSource?.includes('my.labs.livechatinc.com') ||
        utmSource?.includes('my.staging.livechatinc.com') ||
        utmSource?.includes('my.livechatinc.com')

      const payloadKb = {
        metrics: {
          source_url: getMarketplaceSourceUrl(),
          source_id: EVENT_SOURCE_IDS.INSTALL_BUTTON,
          ...utmParams,
          ...(isDirectSource && {
            [SUPPORTED_UTM_PARAMETERS.UTM_SOURCE]: 'direct',
          }),
        },
      }

      options.body = JSON.stringify(payloadKb)
    }

    const response = yield call(fetch, trustedUrl, options)
    const json = yield response.json()

    if (response.status === OK) {
      yield put(installTrustedApp.fulfilled())

      yield put(
        installApp.pending({
          id,
          redirect,
          notification,
          ...(json?.accountStatus && { accountStatus: json.accountStatus }),
        })
      )
    } else {
      throw new Error("Application couldn't be installed: " + json?.message)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.INSTALL)
    yield put(installTrustedApp.rejected({ error: error?.message }))

    if (notification) {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.ERROR,
          content: error?.message,
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    }
  }
}

function* allowNotLoggedInInstall({ id }) {
  yield all([take(userLogIn.fulfilled), take(fetchInstalledApps.fulfilled)])

  const canInstall = yield select(canInstallProduct, id)
  const userHasNotEnoughPermissions = yield select(checkCustomPermissions, id)
  const isItemAvailableInCurrentPlan = yield select(
    isAvailableInCurrentPlan,
    id
  )
  const isTeamPlanProduct = yield select(isTeamPlanApp, id)
  const productDesignation = yield select(getProductDesignation, id)
  const isLiveChat = productDesignation === PRODUCT.LiveChat

  if (!canInstall) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: 'You do not have enough permissions to install applications.',
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    return false
  } else if (!isItemAvailableInCurrentPlan) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `This app is currently available only for ${
          isTeamPlanProduct
            ? 'Team and higher plans'
            : isLiveChat
            ? 'Business and Enterprise plans'
            : 'the Enterprise plan'
        }.`,

        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    return false
  } else if (!userHasNotEnoughPermissions) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `To install contact the owner of the ${productDesignation} license.`,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    return false
  }

  return true
}

export function* requestUninstallApp({ payload }) {
  try {
    const app = yield select(getPublishedApp, payload.id)

    // Cancel subscription
    if (app.authorization) {
      if (app.payment) {
        if (app.payment.frequency === PAYMENT.RECURRENT_CHARGE) {
          yield call(requestRecurrentChargeCancel, {
            payload: {
              clientId: app.authorization.clientId,
              appId: app.id,
            },
          })
        }
      }

      // Remove access to the sso
      yield call(deleteConsent, app.authorization.clientId, 'organization')
    }

    let reason = ''

    if (payload.reasons) {
      reason = payload.reasons.filter((reason) => reason[0]).join(', ')
    }

    const { status, error } = yield call(createApiRequest, {
      method: DELETE,
      path: '/applications/' + payload.id + '/install',
      body: {
        reason,
      },
    })

    if (status === OK) {
      yield delay(1000)
      yield put(
        uninstallApp.fulfilled({
          id: payload.id,
          receivedAt: Date.now(),
        })
      )

      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.NOTIFICATION,
          content:
            app.customProps && app.customProps.isService === 'true'
              ? 'Service deactivated successfully.'
              : 'Application uninstalled successfully.',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      throw new Error("App couldn't be uninstalled: " + error.message)
    }
  } catch (error) {
    yield put(uninstallApp.pending({ error: error?.message }))
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: 'Uninstallation failed. Please, contact our support.',
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

function* addAppToDock({
  payload: { id, isPrivate, appName, appClientId, sourceId },
}) {
  // Apps purchased in carts are going to be handled by new post message event - PM_EVENT.CART_PURCHASED
  if (isAgentApp && sourceId === EVENT_SOURCE_IDS.CART) {
    return
  }

  yield call(() => {
    sendPostMessage(PM_EVENT.APP_INSTALLED, {
      app_name: id,
      app_type: appClientId,
    })

    isPrivate
      ? sendPostMessage(PM_EVENT.APP_EVENT, {
          app_event_action: 'Private app installed',
          app_event_options: {
            appName,
            appType: appClientId,
            id,
          },
        })
      : sendPostMessage(PM_EVENT.APP_EVENT, {
          app_event_action: 'Integration installed',
          app_event_options: {
            integration: appName,
            appType: appClientId,
          },
        })
  })
}

function* removeAppFromDock({
  payload: { id, isPrivate, appName, appClientId },
}) {
  yield call(() => {
    sendPostMessage(PM_EVENT.APP_REMOVED, {
      app_name: id,
      app_type: appClientId,
    })

    isPrivate
      ? sendPostMessage(PM_EVENT.APP_EVENT, {
          app_event_action: 'Private app deleted',
          app_event_options: {
            appName,
            appType: appClientId,
            id,
          },
        })
      : sendPostMessage(PM_EVENT.APP_EVENT, {
          app_event_action: 'Integration deleted',
          app_event_options: {
            integration: appName,
            appType: appClientId,
          },
        })
  })
}

export function* requestInstallTrustedAppNotLoggedIn({ payload }) {
  const allowInstall = yield call(allowNotLoggedInInstall, { id: payload.id })

  if (allowInstall) {
    yield call(requestInstallTrustedApp, { payload })
  }
}

export function* requestInstallAppNotLoggedIn({ payload }) {
  const allowInstall = yield call(allowNotLoggedInInstall, { id: payload.id })

  if (allowInstall) {
    yield call(requestInstallApp, { payload })
  }
}

export function* requestsOnLoginSuccess() {
  yield put(fetchInstalledApps.pending())
}

export function* requestsOnLogoutSuccess() {
  // Reset installed apps
  yield put(
    fetchInstalledApps.fulfilled({
      items: [],
    })
  )
}

export function* sendInstalledAppEvents({
  payload: { id, sourceId, accountStatus = '' },
}) {
  const app = yield select(getPublishedApp, id)
  const { name, payment } = app
  const price = !!payment ? 'paid' : 'free'

  const eventPayload = {
    appId: id,
    appName: name,
    price,
    ...(sourceId && { source_id: sourceId }),
    ...(accountStatus && { accountStatus }),
  }

  yield pushEvent({
    event: 'appInstalled',
    payload: eventPayload,
  })
  yield put(
    logAmplitudeEvent({
      name: AMPLITUDE_EVENTS.APP_INSTALLED,
      params: eventPayload,
    })
  )
}

export function* watchInstalledAppsRequests() {
  yield takeEvery(fetchInstalledApps.pending, requestInstalledAppList)
  yield takeEvery(installApp.pending, requestInstallApp)
  yield takeEvery(installTrustedApp.pending, requestInstallTrustedApp)
  yield takeEvery(installAppNotLoggedIn, requestInstallAppNotLoggedIn)
  yield takeEvery(
    installTrustedAppNotLoggedIn,
    requestInstallTrustedAppNotLoggedIn
  )
  yield takeEvery(uninstallApp.pending, requestUninstallApp)
  yield takeEvery(installApp.fulfilled, addAppToDock)
  yield takeEvery(installApp.fulfilled, sendInstalledAppEvents)
  yield takeEvery(uninstallApp.fulfilled, removeAppFromDock)
  yield takeEvery(userLogIn.fulfilled, requestsOnLoginSuccess)
  yield takeEvery(userLogOut.fulfilled, requestsOnLogoutSuccess)
}
