import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'
import { serializeCart } from '@/utils/reducers/cart'

export const initialState = {
  isFetching: false,
  isAddingProduct: {},
  isInitialized: false,
  acceptedUntil: null,
  agents: null,
  closedAt: null,
  createdAt: null,
  checkedOutUntil: null,
  id: null,
  license: null,
  name: null,
  products: [],
  summary: {},
  purchasedAt: null,
  seats: 0,
  error: null,
  coupon: {
    isFetching: false,
    error: null,
  },
}

export const addProduct = createAsyncActions('cart/product/add')
export const addProductForNotLoggedIn = createAsyncActions(
  'cart/product/add/notLoggedIn'
)
export const addMultipleProducts = createAsyncActions(
  'cart/product/addMultiple'
)
export const addMultipleProductsForNotLoggedIn = createAsyncActions(
  'cart/product/addMultiple/notLoggedIn'
)
export const removeProduct = createAsyncActions('cart/product/remove')
export const updateProduct = createAsyncActions('cart/product/update')
export const load = createAsyncActions('cart/load')
export const create = createAsyncActions('cart/create')
export const addCoupon = createAsyncActions('cart/addCoupon')

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    rejectCartCheckoutSuccess(state) {
      state.checkedOutUntil = null
    },
    cleanCart() {
      return initialState
    },
    saveCart(state, { payload }) {
      return { ...state, ...serializeCart(payload) }
    },
  },
  extraReducers: {
    [addProduct.pending]: (state, { payload }) => {
      state.isAddingProduct = {
        [payload?.productId]: true,
      }
    },
    [addProduct.fulfilled]: (state) => {
      state.isAddingProduct = {}
    },
    [addProduct.rejected]: (state, { payload }) => {
      state.isAddingProduct = {}
      state.error = payload?.error
    },
    [addProductForNotLoggedIn.pending]: (state, { payload }) => {
      state.isAddingProduct = {
        [payload?.productId]: true,
      }
    },
    [addMultipleProducts.pending]: (state) => {
      state.isFetching = true
    },
    [addMultipleProducts.fulfilled]: (state) => {
      state.isFetching = false
    },
    [addMultipleProducts.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [removeProduct.pending]: (state) => {
      state.isFetching = true
    },
    [removeProduct.fulfilled]: (state) => {
      state.isFetching = false
    },
    [removeProduct.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [load.pending]: (state) => {
      state.isFetching = true
    },
    [load.fulfilled]: (state, { payload }) => {
      return { ...state, ...serializeCart(payload) }
    },
    [load.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [create.pending]: (state) => {
      state.isFetching = true
    },
    [create.fulfilled]: (state) => {
      state.isFetching = false
    },
    [create.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [addCoupon.pending]: (state) => {
      state.coupon.isFetching = true
      state.coupon.error = null
    },
    [addCoupon.fulfilled]: (state) => {
      state.coupon.isFetching = false
    },
    [addCoupon.rejected]: (state, { payload }) => {
      state.coupon.isFetching = false
      state.coupon.error = payload?.error
    },
  },
})

export const { rejectCartCheckoutSuccess, cleanCart, saveCart } =
  cartSlice.actions
export const reducer = cartSlice.reducer
