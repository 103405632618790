import { features } from '@/constants/accounts'

import { type Features } from '@/interfaces/accounts'
import { type UserData } from './interfaces'

export const getUserMetaData = (user: UserData) => {
  if (!user || typeof user !== 'object') {
    throw new Error("Incorrect user object, cannot retrieve user's meta data")
  }

  return {
    name: user?.name,
    avatar: user?.properties?.avatar_url,
    email: user?.email,
  }
}

/**
 * Mirrored logic from:
 * https://github.com/livechat/accounts.livechatinc.com/blob/labs/src/routes/consent/scopes.ts#L225
 */
export const getClientFeatures = (scopes: string[] = []): Features => {
  const clientFeatures: Features = {}

  scopes.forEach((scope) => {
    features.forEach(({ name, scopes }) => {
      if (scopes.includes(scope)) {
        if (!clientFeatures[name]) {
          clientFeatures[name] = {
            name,
            scopes: [],
            readonly: true,
          }
        }

        clientFeatures[name].scopes.push(scope)

        if (
          scope.includes('write') ||
          scope.includes(':rw') ||
          scope.includes('manage') ||
          scope.includes(':rc')
        ) {
          clientFeatures[name].readonly = false
        }
      }
    })
  })

  return clientFeatures
}

export const getScopesNames = (features: Features, readonly: boolean) =>
  Object.values(features)
    .filter((feature) => feature.readonly === readonly)
    .map((feature) => feature.name)
