import { graphql, useStaticQuery } from 'gatsby'

/*
How to add new image:
1. Place an image in the src/assets/images folder
2. Add extend gatsby query with gatsbyImageData:
  - docs for all parameters used in gatsbyImageData - https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image
  - property name should match filename but with camelCase notation (and without digits at the beggining)
  - use the best quality image, because it leaves the most space for optimization
  - set image quality between 60-80, these values works the best
How gatsby-plugin-image works:
- reduces images sizes
- cuts them to passed dimensions
- creates multiple image versions for srcSet (1x, 1,5x, 2x)
- creates scrSet with all the fastest supported file formats
- gatsby-plugin-image's components will fallback to png/jpg if browser is not supporting webp format
*/
export const useImages = () => {
  // /**
  // * Please note this image fragment requires a wrapper
  // */

  return useStaticQuery(graphql`
    fragment categoriesImage on File {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE)
      }
    }

    fragment promoBoxes on File {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE)
      }
    }

    query {
      genericApps: file(
        relativePath: { eq: "images/app-hero/generic-apps-2x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 320
            quality: 60
            layout: FIXED
            placeholder: DOMINANT_COLOR
          )
        }
      }
      placeholder: file(
        relativePath: { eq: "images/app-logos/placeholder.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 400
            quality: 60
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      fallbackThumbnail: file(
        relativePath: { eq: "images/app-logos/fallback-thumbnail.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 96
            quality: 70
            layout: CONSTRAINED
            placeholder: NONE
          )
        }
      }
      analytics: file(
        relativePath: { eq: "images/categories-covers/analytics-category.png" }
      ) {
        ...categoriesImage
      }
      cms: file(
        relativePath: { eq: "images/categories-covers/cms-category.png" }
      ) {
        ...categoriesImage
      }
      crm: file(
        relativePath: { eq: "images/categories-covers/crm-category.png" }
      ) {
        ...categoriesImage
      }
      default: file(
        relativePath: { eq: "images/categories-covers/default@4x.png" }
      ) {
        ...categoriesImage
      }
      ecommerce: file(
        relativePath: { eq: "images/categories-covers/ecommerce-category.png" }
      ) {
        ...categoriesImage
      }
      helpdesk: file(
        relativePath: { eq: "images/categories-covers/helpdesk-category.png" }
      ) {
        ...categoriesImage
      }
      email: file(
        relativePath: { eq: "images/categories-covers/email-category.png" }
      ) {
        ...categoriesImage
      }
      cartEmptyState: file(
        relativePath: { eq: "images/cart/empty-state.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            quality: 60
            layout: CONSTRAINED
            placeholder: NONE
          )
        }
      }
      enhanceEcommerceBanner: file(
        relativePath: { eq: "images/enhance-ecommerce/bannerHero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE)
        }
      }
      platformCtaHero: file(
        relativePath: { eq: "images/platform-cta-hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE)
        }
      }
      selfSetupBanner: file(
        relativePath: { eq: "images/self-setup/bannerHero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE)
        }
      }
      launchFinger: file(
        relativePath: { eq: "images/homepage/launch-finger.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 260
            quality: 60
            layout: CONSTRAINED
            placeholder: NONE
          )
        }
      }
      useLiveChat: file(
        relativePath: { eq: "images/homepage/use-livechat.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  `)
}
