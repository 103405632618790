import { type Feature } from '@/interfaces/accounts'

/**
 * Source: https://github.com/livechat/accounts.livechatinc.com/blob/labs/src/routes/consent/scopes.ts#L11
 */
export const features: Feature[] = [
  { name: 'Onboarding', scopes: ['code_manage', 'onboarding_manage'] },
  { name: 'Usage', scopes: ['in_app_msg_manage'] },
  { name: 'Home screen', scopes: ['in_app_msg_manage'] },
  {
    name: 'Chats',
    scopes: [
      'ban_customer',
      'canned_responses_read',
      'canned_responses_write',
      'chats--all:ro',
      'chats--access:ro',
      'chats--my:ro',
      'chats.conversation--all:rw',
      'chats.conversation--access:rw',
      'chats.conversation--my:rw',
      'chats--all:rw',
      'chats--access:rw',
      'chats--my:rw',
    ],
  },
  {
    name: 'Customers',
    scopes: [
      'visitors_read',
      'customers.ban:rw',
      'customers:own',
      'customers:ro',
      'customers:rw',
    ],
  },
  {
    name: 'Archives',
    scopes: [
      'archives_read',
      'archives_write',
      'ban_customer',
      'customers.ban:rw',
      'canned_responses_read',
      'canned_responses_write',
    ],
  },
  {
    name: 'Agents',
    scopes: [
      'agents_read',
      'accounts.password--my:rw',
      'accounts.password--all:rw',
      'accounts.roles--all:ro',
      'accounts.roles--all:rw',
      'accounts.roles.hd--all:rw',
      'accounts.roles.lc--all:rw',
      'agents_write',
      'accounts.roles--all:rw',
      'agents--my:ro',
      'agents--all:ro',
      'agents--my:rw',
      'agents--all:rw',
      'access_rules:ro',
      'access_rules:rw',
      'properties--my:ro',
      'properties--my:rw',
      'properties--all:ro',
      'accounts--my:ro',
      'accounts--my:rw',
      'accounts--all:ro',
      'accounts--all:rw',
      'accounts--all:rc',
      'accounts.invitations--all:ro',
      'accounts.invitations--all:rw',
      'accounts.invitations.hd--all:rw',
      'accounts.invitations.lc--all:rw',
      'accounts.invitations--my:rw',
    ],
  },
  {
    name: 'Chatbots',
    scopes: [
      'agents-bot--all:ro',
      'agents-bot--all:rw',
      'agents-bot--my:rw',
      'agents-bot--all:rw',
      'agents-bot--my:ro',
    ],
  },
  {
    name: 'Groups',
    scopes: [
      'groups_read',
      'groups_write',
      'groups--my:ro',
      'groups--all:ro',
      'groups--my:rw',
      'groups--all:rw',
    ],
  },
  {
    name: 'Tickets',
    scopes: ['tickets_read', 'tickets_write', 'tickets_manage'],
  },
  { name: 'Reports', scopes: ['reports_read', 'reports.export--all:rw'] },
  {
    name: 'Webhooks and integrations',
    scopes: [
      'integrations_read',
      'integrations_manage',
      'webhooks_manage',
      'webhooks_write',
      'webhooks--my:ro',
      'webhooks--my:rw',
      'webhooks--all:ro',
      'webhooks--all:rw',
    ],
  },
  {
    name: 'Subscription',
    scopes: [
      'subscription_manage',
      'subscription:rw',
      'subscription.lc:rw',
      'subscription.hd:rw',
      'billing_manage',
      'billing_admin',
      'ledger_read',
      'invoices_manage',
      'billing--all:ro',
      'billing.info--all:ro',
      'billing.subscriptions--all:ro',
      'billing--all:rw',
      'billing.invoices--all:ro',
    ],
  },
  {
    name: 'Sessions',
    scopes: [
      'sessions--my:ro',
      'sessions--my:rw',
      'sessions_manage',
      'sessions_read',
      'sessions_write',
      'sessions--my:transfer',
      'sessions—all:ro',
      'sessions—all:rw',
    ],
  },
  {
    name: 'Settings',
    scopes: [
      'code_manage',
      'chat_logos_manage',
      'languages_read',
      'languages_write',
      'surveys_read',
      'surveys_manage',
      'forms_manage',
      'greetings_read',
      'greetings_write',
      'eye_catcher_manage',
      'buttons_manage',
      'goals_read',
      'goals_write',
      'canned_responses_read',
      'canned_responses_write',
      'tags_read',
      'tags_write',
      'tags--all:ro',
      'tags--all:rw',
      'tags--groups:ro',
      'tags--groups:rw',
      'routing_read',
      'routing_write',
      'properties_manage',
      'properties_read',
      'properties--configuration:rw',
      'properties.license.value--all:rw',
      'properties.license.value--all:ro',
      'properties.license.value--my:rw',
      'properties.license.value--my:ro',
      'properties.group.value--all:rw',
      'properties.group.value--all:ro',
      'properties.group.value--my:rw',
      'properties.group.value--my:ro',
      'properties.configuration:rw',
      'webhooks_manage',
      'webhooks_write',
      'webhooks--my:ro',
      'webhooks--my:rw',
      'webhooks--all:ro',
      'webhooks--all:rw',
      'webhooks.state:ro',
      'webhooks.state:rw',
      'webhooks.configuration:rw',
      'domain_manage',
      'ban_customer',
      'unban_customer',
      'banlist_read',
      'banlist_write',
    ],
  },
  {
    name: 'Organization settings',
    scopes: [
      'oauth_clients_manage',
      'organization--my:rw',
      'personal_access_manage',
      'clients.consent--my:rw',
      'agents_transfer_ownership',
      'organization.product--my:rw',
    ],
  },
  {
    name: 'Developers program',
    scopes: [
      'dev_platform_admin',
      'dps.apps--all:read',
      'dps.apps--my--public:manage',
      'dps.submissions--all:manage',
      'dps.submissions--all:read',
    ],
  },
  { name: 'Tools', scopes: ['ghost', 'multicast:rw', 'multicast:ro'] },
]
