import { compareDesc } from 'date-fns'

import { getUrlParams } from '@/utils/browser'
import { SORT, SORT_DEVS } from '@/constants'

export const isSupportedSortOption = (sort, options) =>
  !!options.find(({ VALUE }) => VALUE === sort)

export const getInitialSortValue = (defaultSortValue) => {
  const { sortBy } = getUrlParams()
  return sortBy || defaultSortValue || ''
}

export const sortByPopularity = (a, b) => b.installations - a.installations

export const sortByPublishedDate = (a, b) => {
  return compareDesc(a.publishedAt, b.publishedAt)
}

export const sortAlphabetically =
  (property = 'name') =>
  (a, b) => {
    let aValue = a[property] || a
    let bValue = b[property] || b

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      aValue = aValue.toUpperCase()
      bValue = bValue.toUpperCase()
    }

    if (aValue < bValue) {
      return -1
    }
    if (aValue > bValue) {
      return 1
    }
    return 0
  }

export const sortByOrder = (a, b) => {
  const orderA = a.customProps?.order ? parseInt(a.customProps.order, 10) : 0
  const orderB = b.customProps?.order ? parseInt(b.customProps.order, 10) : 0

  return orderB - orderA
}

export const sortByOrderAndInstallations = (a, b) => {
  const orderA = a.customProps?.order ? parseInt(a.customProps.order, 10) : 0
  const orderB = b.customProps?.order ? parseInt(b.customProps.order, 10) : 0

  return orderB - orderA || b.installations - a.installations
}

export const sortByPaymentAndInstallations = (a, b) => {
  const aPrice = a.payment && a.payment.price
  const bPrice = b.payment && b.payment.price

  return bPrice - aPrice || b.installations - a.installations
}

export const sortDevsByProductsNumber = (a, b) => {
  const aProducts = a.services.length + a.apps.length
  const bProducts = b.services.length + b.apps.length
  return bProducts - aProducts
}

export const getSortFunction = (sort, sortByProperty) => {
  switch (sort) {
    case SORT.ALPHABETICALLY.VALUE:
      return sortAlphabetically(sortByProperty)

    case SORT.PRICE.VALUE:
      return sortByPaymentAndInstallations

    case SORT.POPULARITY.VALUE:
      return sortByPopularity

    case SORT.RECOMMENDED.VALUE:
      return sortByOrderAndInstallations

    case SORT.PUBLISHED.VALUE:
      return sortByPublishedDate

    case SORT_DEVS.PRODUCTS_NUMBER.VALUE:
      return sortDevsByProductsNumber

    case SORT_DEVS.DEVELOPER_NAME.VALUE:
      return sortAlphabetically('company')

    default:
      return null
  }
}

export const sortOn = (property, orderList) => {
  const order = orderList.reduce(
    (obj, key, index) => ({
      ...obj,
      [key]: index + 1,
    }),
    {}
  )
  const getVal = (item) => order[item[property]] || Infinity

  return (a, b) => getVal(a) - getVal(b)
}
