import { useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { canUseWindow } from './window'

export const preventScroll = (prevent) => {
  if (typeof window !== 'undefined') {
    window.document.body.style.overflow = prevent ? 'hidden' : 'auto'
  }
}

export const usePreventScroll = () => {
  useEffect(() => {
    preventScroll(true)

    return () => {
      preventScroll(false)
    }
  })
}

/* 
  Gatsby pass incorrect location.hash on init if URL contains also query params, etc.
  Function helps to get always only hash value.
*/
const normalizeHashId = (id) => id.split('?')['0']

export const scrollTo = (id) => {
  try {
    if (!id || id.includes('#access_token')) {
      return null
    }

    const hash = normalizeHashId(id)
    const el = document.querySelector(hash)

    if (el) {
      return window.scrollTo(0, el.offsetTop - 20)
    }
  } catch (error) {
    return null
  }
}

export const preventScrollOnBody = (isOpen, element, options) => {
  if (canUseWindow) {
    let scrollableElement = null

    if (element) {
      scrollableElement =
        typeof element === 'string' ? document.querySelector(element) : element
    }

    if (scrollableElement) {
      if (isOpen) {
        disableBodyScroll(scrollableElement, options)
      } else {
        enableBodyScroll(scrollableElement)
      }
    }
  }
}
