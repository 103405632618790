import React from 'react'
import { string, object } from 'prop-types'

import { URLS } from '@/constants'
import Image from '@/components/Image'

export const getCategoryImage = ({
  name,
  id,
  alt,
  imgStyles,
  ...imageProps
}) => {
  let filename

  switch (true) {
    case name === URLS.CATEGORY.ANALYTICS.LABEL:
      filename = 'analytics'
      break

    case URLS.CATEGORY.CMS.LABEL.includes(name):
      filename = 'cms'
      break

    case URLS.CATEGORY.CRM.LABEL.includes(name):
      filename = 'crm'
      break

    case name === URLS.CATEGORY.ECOMMERCE.LABEL:
      filename = 'ecommerce'
      break

    case name === URLS.CATEGORY.EMAIL_MARKETING.LABEL:
      filename = 'email'
      break

    case name === URLS.CATEGORY.HELPDESK.LABEL:
      filename = 'helpdesk'
      break

    default:
      filename = 'default'
      break
  }

  return (
    <Image
      type={filename}
      alt={alt || filename}
      imgStyles={imgStyles}
      {...imageProps}
    />
  )
}

getCategoryImage.propTypes = {
  name: string,
  id: string,
  alt: string,
  imgStyles: object,
}
