import { Apps, Messages, Report } from '@livechat/design-system-icons'
import { APP_WIDGET_PLACEMENT } from './utils'

export const WIDGET_CARD_MAP = {
  [APP_WIDGET_PLACEMENT.FULL_SCREEN]: {
    placement: APP_WIDGET_PLACEMENT.FULL_SCREEN,
    icon: Apps,
    section: 'Product',
    title: 'Apps',
    description:
      'You can pin the full-screen view of this app to the product navigation.',
  },
  [APP_WIDGET_PLACEMENT.SETTINGS]: {
    placement: APP_WIDGET_PLACEMENT.SETTINGS,
    icon: Apps,
    section: 'Apps',
    title: 'App settings',
    description:
      'This app provides a Settings view, which you can use to manage its configuration.',
  },
  [APP_WIDGET_PLACEMENT.PLUGIN]: {
    placement: APP_WIDGET_PLACEMENT.PLUGIN,
    icon: Messages,
    section: 'Chats',
    title: 'Customer details',
    description:
      'Access the app from the customer details on the right sidebar in the Chats view.',
  },
  [APP_WIDGET_PLACEMENT.MESSAGE_BOX]: {
    placement: APP_WIDGET_PLACEMENT.MESSAGE_BOX,
    icon: Messages,
    section: 'Chats',
    title: 'Message box',
    description:
      'This feature is accessible when you are actively chatting with the customer.',
  },
  /* Special case - customProps.agentAppReport === 'true' */
  reports: {
    placement: 'reports',
    icon: Report,
    section: 'Reports',
    title: 'Report',
    description:
      'This app unlocks a new report under Reports for you and your team.',
  },
} as const

export const WIDGET_CARD_ORDER = [
  APP_WIDGET_PLACEMENT.FULL_SCREEN,
  APP_WIDGET_PLACEMENT.SETTINGS,
  APP_WIDGET_PLACEMENT.PLUGIN,
  APP_WIDGET_PLACEMENT.MESSAGE_BOX,
  'reports',
]

export const WIDGET_CARD_LINK = {
  CHATS: '/chats',
  REPORTS: '/reports',
} as const
