import { canUseWindow, isInIframe, isInNestedIframe } from '@/utils/browser'

const baseUrl = process.env.GATSBY_BASE_URL

export const getMarketplaceSourceUrl = () => {
  if (!canUseWindow) {
    return baseUrl
  }

  if (isInNestedIframe()) {
    return baseUrl
  } else if (isInIframe()) {
    return `${process.env.GATSBY_IFRAME_URL}/marketplace`
  }

  return baseUrl
}

export const normalizeRouteUpdatePath = (pathname, search) => {
  let normalizedPath = pathname

  if (
    !pathname ||
    pathname === '/apps/marketplace' ||
    pathname === '/apps/marketplace/'
  ) {
    normalizedPath = '/apps'
  }

  if (normalizedPath.length > 0 && !normalizedPath.startsWith('/')) {
    normalizedPath = `/${normalizedPath}`
  }

  if (normalizedPath.endsWith('/')) {
    normalizedPath = normalizedPath.slice(0, -1)
  }

  return `/apps/marketplace${normalizedPath}${search}`
}
